$color-yellow: '#f5c61a';
$mobile: '600px';
@import './node_modules/react-modal-video/scss/modal-video.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #333333;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;

  @media (max-width: 1400px) {
    padding: 0 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
}


body {
  font-family: "Nunito", "Helvetica", "Arial", sans-serif;
}

img {
  max-width: 100%;
}

.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows:1fr 48px;

  @media (max-width: $mobile) {
    min-height: 98vh;
  }
}

.header {
  border-bottom: 1px solid #e4e6e5;
  background: #f5f5f5;
  height: 52px;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 444;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_menu {
  margin-left: 20px;
  position: relative;
  z-index: 99999;
}

.header_menu_icon {
  max-width: 22px;
}

.footer {
  border-top: 1px solid #e4e6e5;
  background: white;
  height: 48px;
  padding: 12px 24px;
  position: relative;
  z-index: 3;
}

.footer__title {
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.footer__title__link {
  color: #52bbab;
  text-decoration: none;
  padding-left: 10px;
  font-weight: 600;
}

.logo {
  max-height: 52px;
  transform: scale(2);
  padding-left: 20px;
  width: 90px;
}

//LOGIN

.login {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url('./images/login_bg.png');

  @media (max-width: $mobile) {
    background-image: none;
    background-color: white;
  }

}

.login_modal__wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_modal {
  position: relative;
  z-index: 5;
  background: white;
  width: 28rem;
  max-width: 100vw;
  height: min-content;
  padding: 1.5rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0.13rem;


  @media (max-width: $mobile) {
    box-shadow: none;
  }
}

.login_title {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 0.5rem;
}

.login_subtitle {
  color: #999999;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.wrapper_button {
  margin-top: 30px;
}

.wrapper_input {
  margin-top: 20px;
}

.login_button__text {
  color: white;
  font-weight: 700;
  line-height: 1.25rem;
  font-size: 0.88rem;
}

// COURSE PAGE

.course-page {
  position: relative;
}

.course-page__line__top {
  position: fixed;
  top: 52px;
  z-index: 444;
  height: 2rem;
  width: 100%;
  background: #52bbab;
}

.course-page__wrapper {
  margin-top: calc(2rem + 52px);
}

.course-page__startsite {
  min-height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 24%);
  background: white;
  padding: 16px;
}

.startsite_wrapper {
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
}

.startsite_image {
  width: 48px;
  height: 48px;
}

.startsite_title {
  color: #333333;
  line-height: 24px;
  font-size: 1.13rem;
  font-weight: 600;
  margin-left: 15px;
}

.main_content {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-gap: 30px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.main_conten_wrapper__text {
  text-align: center;
  height: 140px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main_content_wrapper__text__right{
  text-align: right;
  padding-right: 20px;
}

.main_conten_wrapper__text__title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.main_conten_wrapper__text__btn {
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.main_content__footer {
  margin-top: 40px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -10px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_conten_wrapper {
  padding-top: 25px;
  padding: 1.5rem;
}

.main_content_header_img {
  width: 100%;
}

.main_content_video__wrapper {

}

.main_content_video__title {
  margin-bottom: 1rem;
  font-size: 1.13rem;
  font-weight: 600;
  color: #333333;
}

.main_content_video {
  position: relative;
  width: 100%;
}

.main_content_list {

}

.main_content_list__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  height: 310px;

  @media (max-width: $mobile) {
    height: auto;
    grid-template-columns: 1fr 1fr;
  }
}

.main_content_list__title {
  margin-bottom: 1rem;
  font-size: 1.13rem;
  font-weight: 600;
  color: rgb(82, 187, 171);
}

.course_card {
  z-index: 111;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  background: white;
}

.course_card_one {
  position: absolute;
  z-index: 1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  bottom: -4px;
  left: 3px;
  height: 4px;
  right: 3px;
}

.course_card_two {
  position: absolute;
  z-index: 1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  bottom: -8px;
  left: 5px;
  height: 4px;
  right: 5px;
}

.course_card_image {
  height: 110px;
  width: 100%;
  background-size: cover!important;
  background-repeat: no-repeat;

  @media (max-width: $mobile) {
    height: 100px;
  }
}


.course_card__title {
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #333333;
}

.course_card__subtitle {
  font-weight: 600;
  color: #333333;
}

.course_card__count {
  color: grey;
  margin-top: 5px;
}

.course_card_content {
  padding: 10px;
  margin-bottom: 23px;
}

.course_card_footer {
  border-top: 1px solid rgba(128, 128, 128, 0.3);
  padding: 10px;
  display: flex;
}

.course_card_footer__text {
  margin-left: 10px;
}

.main_content__social {
  margin-top: 40px;
  position: relative;
}

.main_content__social_block__img {
  background-repeat: no-repeat;
  height: 70px;
  width: 140px;
  margin-right: 10px;
  background-size: cover;
}

.main_content__social_block {
  width: 100%;
  margin-top: 30px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.main_content__social_block__text {
  display: flex;
  flex-direction: column;
}

.wrapper_buttons_footer {
  max-width: 80%;
  margin: auto;
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 15px;
  }
}
